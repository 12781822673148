import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import { Form, Input, Button } from 'antd';
import HeaderComponent from '../components/header/header';
import { Typography } from 'antd';
const { Text } = Typography;

const RecoverAccount = () => {

  return (
    <Layout className="site-layout-background">
      <HeaderComponent title="Recuperar Cuenta" /><br/>
      <Text strong className="text-primary" >Ingresa el correo electrónico de la cuenta a recuperar</Text>
      <br/>
      <Form name="normal_login">
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: 'Enter your email!',
            },
          ]}><Input
            bordered={true}
            placeholder="correo@emial.com"
            suffix="Email"
            size="large"
            className="form-item text-primary"/>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary" size="large" block >
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </Layout>
  );
};

RecoverAccount.propTypes = {
  data: PropTypes.any,
};

RecoverAccount.defaultProps = {
  data: '',
};

export default RecoverAccount;
